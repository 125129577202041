import "./App.scss";

import NavbarMain from "./containers/common/navbar/NavbarMain";
import RoutesProvider from "./router/routes";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import React from "react";
import Wrapper from "./components/Wrapper/Wrapper";

import LoginMain from "./containers/LoginMain/LoginMain";

function App() {
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (token) navigate("/Home");
  //   else navigate("/Login");
  // }, [token]);

  useEffect(() => {
    navigate("/Login");
  }, []);

  let token = true;

  return (
    <Wrapper>
      {token ? (
        <LoginMain />
      ) : (
        <>
          <NavbarMain />
          <RoutesProvider />
        </>
      )}
    </Wrapper>
  );
}

export default App;
