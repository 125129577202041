import React, { useState } from "react";
import logo from "../../assets/image/mc-utilities-logo.jpg";
import background1 from "../../assets/image/login-background-1.jpg";

import SignUpForm from "../../components/LoginPage/SignUpForm";
import LoginForm from "../../components/LoginPage/LoginForm";

import "./LoginMain.scss";

function LoginMain() {
  const [showSignUp, setShowSignUp] = useState(false);

  const changeForm = () => {
    showSignUp ? setShowSignUp(false) : setShowSignUp(true);
  };

  return (
    <>
      <div
        className="background-image"
        style={{ backgroundImage: `url(${background1})` }}
      />
      <div className="main-login">
        <div className="login-box">
          <img className="login-logo" src={logo} alt="logo" />
          <div className="form-container">
            {showSignUp ? <SignUpForm /> : <LoginForm />}
            <label
              className="noAccount-label toggle-label"
              onClick={changeForm}
            >
              {showSignUp
                ? "I already have an account"
                : "I don't have an account"}
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginMain;
