import { InputText } from "primereact/inputtext";
import React, { useRef, useState } from "react";

import "./LoginPage.scss";

import grassBlockIcon from "../../assets/image/grass-block-icon.jpg";
import { Button } from "primereact/button";

function SignUpForm() {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isSignupClicked, setIsSignupClicked] = useState(false);

  const buttonRef = useRef(null);

  const onClickSignup = () => {
    setIsSignupClicked(true);
  };

  return (
    <>
      <div className="login-card">
        <label className="signup-title">Signup</label>
        <div className="form-columns">
          <div className="left-column">
            <div className="field mb-3">
              <label className="login-label login-label-field" htmlFor="name">
                Name
              </label>
              <InputText
                className="login-input"
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your name"
              />
            </div>

            <div className="field mb-3">
              <label
                className="login-label login-label-field"
                htmlFor="surname"
              >
                Surname
              </label>
              <InputText
                className="login-input"
                id="surname"
                value={surname}
                placeholder="Enter your surname"
                type="text"
                onChange={(e) => setSurname(e.target.value)}
              />
            </div>

            <div className="field mb-3">
              <label className="login-label login-label-field" htmlFor="email">
                Email
              </label>
              <InputText
                className="login-input"
                id="email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
            </div>
          </div>

          <div className="right-column">
            <div className="field mb-3">
              <label
                className="login-label login-label-field"
                htmlFor="username"
              >
                Username
              </label>
              <InputText
                className="login-input"
                id="username"
                value={username}
                placeholder="Choose your username"
                type="text"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className="field mb-3">
              <label
                className="login-label login-label-field"
                htmlFor="password"
              >
                Password
              </label>
              <InputText
                className="login-input"
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Choose your password"
              />
            </div>

            <div className="field mb-3">
              <label
                className="login-label login-label-field"
                htmlFor="confirmPassword"
              >
                Confirm password
              </label>
              <InputText
                className="login-input"
                id="confirmPassword"
                value={confirmPassword}
                placeholder="Confirm your password"
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="login-button-container">
          <Button
            className="login-button"
            ref={buttonRef}
            onClick={() => onClickSignup()}
            style={{
              backgroundImage: `url(${grassBlockIcon})`,
              backgroundSize: "50px 50px",
            }}
          >
            <label className="login-label login-button-label">Signup</label>
          </Button>
        </div>
      </div>
    </>
  );
}

export default SignUpForm;
