import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import "./LoginPage.scss";

import grassBlockIcon from "../../assets/image/grass-block-icon.jpg";

import React, { useRef, useState } from "react";

import endManStill from "../../assets/image/enderman-still.png";
import endManWithGrass from "../../assets/image/enderman-with-block.png";

import clickSound1 from "../../assets/sounds/endMan-tp-in.mp3";
import clickSound2 from "../../assets/sounds/endMan-idle3.mp3";
import clickSound3 from "../../assets/sounds/endMan-tp-out.mp3";

const LoginForm = () => {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  const [isLoginClicked, setIsLoginClicked] = useState(false);

  const [endManStillVisibility, setEndManStillVisibility] = useState<
    "visible" | "hidden"
  >("hidden");

  const [endManWithGrassVisibility, setEndManWithGrassVisibility] = useState<
    "visible" | "hidden"
  >("hidden");

  const [loginButtonVisibility, setLoginButtonVisibility] = useState<
    "visible" | "hidden"
  >("visible");

  const buttonRef = useRef(null);

  const audioRef1 = useRef(new Audio(clickSound1));
  const audioRef2 = useRef(new Audio(clickSound2));
  const audioRef3 = useRef(new Audio(clickSound3));

  const onClickLogin = () => {
    setIsLoginClicked(true);
    const timer1 = setTimeout(() => {
      setEndManStillVisibility("visible");

      audioRef1.current.play();
    }, 200);

    const timer2 = setTimeout(() => {
      setEndManStillVisibility("hidden"); // Cambia la visibilità dopo 3 secondi
      setLoginButtonVisibility("hidden");
      setEndManWithGrassVisibility("visible");
      audioRef2.current.play();
    }, 1000);

    const timer3 = setTimeout(() => {
      setEndManWithGrassVisibility("hidden");
      audioRef3.current.play();
    }, 2500);

    // Pulisci il timer se il componente viene smontato
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  };

  return (
    <>
      <div className="login-card">
        <label className="login-title">Login</label>
        <div className="field mb-3 username-field">
          <label
            className="login-label"
            htmlFor="username"
            style={{ fontSize: "large" }}
          >
            Username
          </label>
          <InputText
            style={{ width: "100%", height: "35px" }}
            id="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter your username"
          />
        </div>

        <div className="field mb-3 password-field">
          <label
            className="login-label"
            htmlFor="password"
            style={{ fontSize: "large" }}
          >
            Password
          </label>
          <InputText
            style={{ width: "100%", height: "35px" }}
            id="password"
            value={password}
            placeholder="Enter your password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="login-button-container">
          <Button
            className="login-button"
            ref={buttonRef}
            onClick={() => onClickLogin()}
            style={{
              backgroundImage: `url(${grassBlockIcon})`,
              backgroundSize: "50px 50px",
              visibility: loginButtonVisibility,
            }}
          >
            <label className="login-label login-button-label">Login</label>
          </Button>
        </div>

        <img
          className="enderman-still"
          src={endManStill}
          height={150}
          alt=""
          style={{ visibility: endManStillVisibility }}
        />

        <img
          className="enderman-with-grass"
          src={endManWithGrass}
          height={135}
          alt=""
          style={{ visibility: endManWithGrassVisibility }}
        />
      </div>
    </>
  );
};

export default LoginForm;
